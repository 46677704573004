import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useQuery } from "@vue/apollo-composable";
import { hasValue } from "~/helpers/common-helpers";
import moment from "moment";
export var useQueryWithPeriod = function useQueryWithPeriod(_ref) {
  var document = _ref.document,
    variables = _ref.variables,
    options = _ref.options,
    period = _ref.period;
  var isPeriodVariables = function isPeriodVariables(variables) {
    return "input" in variables;
  };
  var _variables = function _variables() {
    var variablesArg = unref(typeof variables === "function" ? variables() : variables !== null && variables !== void 0 ? variables : {});
    var format = function format(date) {
      return date ? moment.utc(date).toISOString() : undefined;
    };
    if (isPeriodVariables(variablesArg)) {
      var _period$value, _period$value2, _period$value3;
      variablesArg.input["from"] = format((_period$value = period.value) === null || _period$value === void 0 ? void 0 : _period$value.from);
      variablesArg.input["to"] = format((_period$value2 = period.value) === null || _period$value2 === void 0 ? void 0 : _period$value2.to);
      variablesArg.input["periodType"] = (_period$value3 = period.value) === null || _period$value3 === void 0 ? void 0 : _period$value3.periodType;
    }
    return variablesArg;
  };
  var _options = function _options() {
    var optionsArg = unref(typeof options === "function" ? options() : options !== null && options !== void 0 ? options : {});
    var periodSelected = !!period.value;
    var o = _objectSpread(_objectSpread({}, optionsArg), {}, {
      enabled: (!hasValue(optionsArg.enabled) || optionsArg.enabled) && periodSelected
    });
    return o;
  };
  var query = useQuery(document, _variables, _options);
  return query;
};